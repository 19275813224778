var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"px-4"},[_c('v-col',{staticClass:"my-auto py-0",attrs:{"md":"3"}},[_c('label',{staticClass:"btx-label mt-2 required",attrs:{"for":"display-name"}},[_vm._v("Display Name "),_c('TooltipQuestion',{scopedSlots:_vm._u([{key:"text",fn:function(){return [_vm._v("This name will be displayed on"),_c('br'),_vm._v("the transactions you create for"),_c('br'),_vm._v("this address")]},proxy:true}])})],1)]),_c('v-col',{staticClass:"py-0",attrs:{"md":"9"}},[_c('TextInput',{class:{
				required: !_vm.address.display_name,
			},attrs:{"hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"display-name","rules":[_vm.vrules.required(_vm.address.display_name, 'Display Name')],"placeholder":"Display Name"},model:{value:(_vm.address.display_name),callback:function ($$v) {_vm.$set(_vm.address, "display_name", $$v)},expression:"address.display_name"}})],1),_c('v-col',{staticClass:"my-auto py-0",attrs:{"md":"3"}},[_c('label',{staticClass:"btx-label mt-2 required",attrs:{"for":"company-name"}},[_vm._v("Company Name ")])]),_c('v-col',{staticClass:"py-0",attrs:{"md":"9"}},[_c('TextInput',{class:{
				required: !_vm.address.company_name,
			},attrs:{"hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"company-name","rules":[_vm.vrules.required(_vm.address.company_name, 'Company Name')],"placeholder":"Company Name"},model:{value:(_vm.address.company_name),callback:function ($$v) {_vm.$set(_vm.address, "company_name", $$v)},expression:"address.company_name"}})],1),_c('v-col',{staticClass:"my-auto py-0",attrs:{"md":"3"}},[_c('label',{staticClass:"btx-label mt-2 required",attrs:{"for":"address-phone-no"}},[_vm._v("Phone")])]),_c('v-col',{staticClass:"py-0",attrs:{"md":"9"}},[_c('PhoneTemplate',{class:{ required: !_vm.address.phone_no },attrs:{"hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"address-phone-no","placeholder":"Phone No.","rules":[_vm.vrules.required(_vm.address.phone_no, 'Phone No')]},model:{value:(_vm.address.phone_no),callback:function ($$v) {_vm.$set(_vm.address, "phone_no", $$v)},expression:"address.phone_no"}})],1),_c('v-col',{staticClass:"my-auto py-0",attrs:{"md":"3"}},[_c('label',{staticClass:"btx-label mt-2 required",attrs:{"for":"address-email"}},[_vm._v("Email Address")])]),_c('v-col',{staticClass:"py-0",attrs:{"md":"9"}},[_c('EmailInput',{class:{ required: !_vm.address.email },attrs:{"hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"address-email","placeholder":"Email Address","rules":[_vm.vrules.required(_vm.address.email, 'Email Address')]},model:{value:(_vm.address.email),callback:function ($$v) {_vm.$set(_vm.address, "email", $$v)},expression:"address.email"}})],1),_c('v-col',{staticClass:"mt-2 py-0",attrs:{"md":"3"}},[_c('label',{staticClass:"btx-label mt-2 required",attrs:{"for":`address-street-1-${_vm.stringId}`}},[_vm._v("Address")])]),_c('v-col',{staticClass:"py-0",attrs:{"md":"9"}},[_c('TextAreaInput',{class:{
				required: !_vm.address.address_line_1,
			},attrs:{"hide-details":"","id":`address-street-1-${_vm.stringId}`,"placeholder":"Address","disabled":_vm.pageLoading,"rules":[_vm.vrules.required(_vm.address.address_line_1, 'Address')],"loading":_vm.pageLoading},model:{value:(_vm.address.address_line_1),callback:function ($$v) {_vm.$set(_vm.address, "address_line_1", $$v)},expression:"address.address_line_1"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }